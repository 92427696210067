import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Head = _resolveComponent("Head");

  var _component_QuestionCard = _resolveComponent("QuestionCard");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Head, {
    title: "网上考试",
    back: true
  }), _ctx.Data.isQuestion ? (_openBlock(), _createBlock(_component_QuestionCard, {
    key: 0,
    ref: "sumst",
    list: _ctx.Data.list,
    listscore: _ctx.Data.listScore,
    listtime: _ctx.Data.listTime,
    historyid: String(_ctx.Data.historyId),
    disabled: _ctx.Data.disabled,
    onGetCardChildren: _ctx.getCardChildren
  }, null, 8, ["list", "listscore", "listtime", "historyid", "disabled", "onGetCardChildren"])) : _createCommentVNode("", true)], 64);
}